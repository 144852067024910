<template>
  <div id="MyFinancialMsg">
    <el-row>
      <el-col :span="22" :offset="1">
        <el-row>
          <el-col :span="3">
            <el-button
              :type="selectNav == '0' ? 'primary' : 'info'"
              @click="selectNav = '0'"
              >入金记录</el-button
            >
          </el-col>
          <el-col :span="3">
            <el-button
              :type="selectNav == '1' ? 'primary' : 'info'"
              @click="selectNav = '1'"
              >出金记录</el-button
            >
          </el-col>
          <el-col :span="4">
            <el-button
              :type="selectNav == '2' ? 'primary' : 'info'"
              @click="selectNav = '2'"
              >资金划转记录</el-button
            >
          </el-col>
          <el-col :span="8">
            <el-date-picker
              v-model="selectData"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyyMMdd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="24">
            <span class="datapicker_tip">
              <img
                src="../../../assets/images/i.png"
                alt=""
                class=""
                style="vertical-align: middle;display: inline-block; margin: 0 5px 5px 0;"
              />
              默认查询当日记录,选择开始日期和结束日期将查询历史记录
            </span>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="22" :offset="1">
        <el-table :data="tableData" style="width: 100%; min-height: 400px;">
          <template slot="empty">
            <div class="no-datas1">
              <div
                class="no-data-img"
                style="margin: 100px auto 0px; width: 120px; height: 100px;"
              >
                <img
                  src="../../../assets/images/no_data.png"
                  alt=""
                  style="width: 100%;"
                />
              </div>
              <div
                class="no-data"
                style="margin-top: -25px !important; text-align: center;"
              >
                {{ $t("message.No_data") }}
              </div>
            </div>
          </template>
          <el-table-column prop="occurTime" label="时间" width="180">
            <template slot-scope="scope">{{
              scope.row.occurTime | dateFormate
            }}</template>
          </el-table-column>
          <el-table-column
            prop="bizName"
            label="类型"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="金额"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="marketName"
            label="市场"
            width="180"
          ></el-table-column>
          <el-table-column prop="note" label="流水号"></el-table-column>
        </el-table>

        <el-pagination
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
/*global http protocolFwd*/
export default {
  filters: {
    formatType(bizCode) {
      switch (bizCode) {
        case 101:
          return "入金";
        case 102:
          return "出金";
        case 103:
          return "其它";
        default:
          return "未知";
      }
    }
  },
  data() {
    return {
      selectNav: "0",

      tableData: [],
      pageData: {
        total: 10,
        pageSizes: [5, 10, 20],
        pageSize: 10,
        currentPage: 1
      },
      selectData: ""
    };
  },
  watch: {
    selectData(val, oldVal) {
      this.pageData = {
        total: 10,
        pageSizes: [5, 10, 20],
        pageSize: 10,
        currentPage: 1
      };
      this.tableData = [];
      this.param_queryMyIntegralDetail();
    },
    selectNav(val, oldVal) {
      this.pageData = {
        total: 10,
        pageSizes: [5, 10, 20],
        pageSize: 10,
        currentPage: 1
      };
      this.tableData = [];
      this.param_queryMyIntegralDetail();
    }
  },
  mounted() {
    this.param_queryMyIntegralDetail();
  },
  methods: {
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.param_queryMyIntegralDetail();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.param_queryMyIntegralDetail();
    },
    param_queryMyIntegralDetail() {
      // // console.log(this.selectData)
      let protocolUrlData = {};
      if (this.selectData) {
        // 历史
        protocolUrlData = protocolFwd.param_queryMyIntegralDetailHis;
        protocolUrlData.param.starTime = this.selectData[0];
        protocolUrlData.param.endTime = this.selectData[1];
      } else {
        // 当前
        protocolUrlData = protocolFwd.param_queryMyIntegralDetail;
      }
      if (this.selectNav == "0") {
        // 入金
        protocolUrlData.param.typeCode = 101;
      } else if (this.selectNav == "1") {
        // 出金
        protocolUrlData.param.typeCode = 102;
      } else {
        // 其它
        protocolUrlData.param.typeCode = null;
      }
      protocolUrlData.param.page = this.pageData.currentPage - 1;
      protocolUrlData.param.size = this.pageData.pageSize;
      http.postFront(protocolUrlData).then(response => {
        if (response.data) {
          if (response.data && response.data.code == "0") {
            this.pageData.total = response.data.value.totalElements;
            const arr = response.data.value.content;
            this.tableData = arr;
          }
        }
      });
    }
  }
};
</script>
<style>
#MyFinancialMsg .el-tabs__item {
  font-size: 16px;
  height: 50px;
  line-height: 50px;
}
#MyFinancialMsg .el-dialog__footer {
  padding: 20px;
}
#sec .el-tabs__item {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.el-pagination {
  margin-top: 15px;
}

#MyFinancialMsg .datapicker_tip {
  display: inline-block;
  line-height: 30px;
  height: 30px;
  padding: 10px 0;
  color: #abafbb;
}
</style>
